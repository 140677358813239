import * as React from "react"
import Project from "../components/layout/project"

type Props = {
  data: {
    project: any
    [key: string]: any
  }
  pageContext: {
    prev: any
    next: any
  }
  [key: string]: any
}

export default function EmmaCoreProject({ ...props }: Props) {
  return <Project {...props} />
}
