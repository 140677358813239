/** @jsx jsx */
import { Flex, jsx, Themed } from "theme-ui"
import Item from "./project-info-item"
import { Button } from "theme-ui";

type ProjectInfoProps = {
  project: {
    medium: string
    technologies: string
    link: string
    title: string
  }
}

const ProjectInfo = ({ project }: ProjectInfoProps) => (
  <Flex sx={{ mt: 4, mb: [2, 5], flexWrap: `wrap` }}>
    <Item name="Medium" content={project.medium} />
    <Item name="Technologies" content={project.technologies} />

    {project.link &&
      <Themed.a
        href={project.link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`${project.title} link`}
        sx={{ paddingTop: `10px` }}
      >
        <Button sx={{ variant: `buttons.contact`, fontWeight: `semibold`, px: 3, }}>
          View Project
        </Button>
      </Themed.a>
    }

  </Flex >
)

export default ProjectInfo
